import React from "react"
import loadable from "@loadable/component"

const Jumbotron = loadable(() => import("./components/jumbotron"))
const CopyBlocks = loadable(() => import("./components/copy-blocks"))
const NowWhat = loadable(() => import("./components/now-what"))

const EyesOnTheSummit = () => {
  return (
    <section>
      <Jumbotron />
      <CopyBlocks />
      <NowWhat />
    </section>
  )
}

export default EyesOnTheSummit
